body {
   
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
    color: #282c34;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
